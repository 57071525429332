import React, { ReactElement } from 'react';
import './Rules.scss';

const Et: React.FC = (): ReactElement => {
  return (
    <div className="Et rules-wrapper">
      <h2>Kampaania reeglid</h2>
      <ol>
        <li>
          Tere kampaania „Osta Emma tooteid ja võid võita „Lotte ja Kadunud
          jõuluvana“ etenduse piletid!“ on tarbijamäng, mis toimub perioodil
          18.09-30.11.2023 (edaspidi Kampaania), mida korraldab TERE AS, Lelle
          22, Tallinn, Harjumaa, 11318, EESTI (edaspidi Korraldaja). 
        </li>
        <li>
          Kampaania läbiviimise kord on kehtestatud Korraldaja poolt
          käesolevates reeglites. Kõik Korraldaja otsused kampaania läbiviimisel
          on lõplikud ning kõigile kampaanias osalejatele kohustuslikud.
        </li>
        <li>
          Kampaania auhinnafondis on peaauhinnana „Lotte ja Kadunud jõuluvana“
          etenduse 10 perepiletit. Perepilet tähendab maksimaalselt 5 etenduse
          piletit võidu, st ühe pere kohta. Vaheauhindadena loositakse 5
          komplekti Tere Emma toodetega kinkekotti. Kinkekotti kuuluvad tooted
          on loetletud Tere kodulehel:{' '}
          <a
            href="http://www.tere.eu/brandid/emma"
            target="_blank"
            rel="noreferrer"
          >
            www.tere.eu/brandid/emma
          </a>
          . Vaheauhindasid loositakse 5 komplekti ühe loosimise perioodi kohta
          ehk kogu kampaania perioodil kokku 15 komplekti.
        </li>
        <li>
          Kampaanias osalemiseks tuleb osta vähemalt üks Tere Emma toode ja
          sisestada ostutšeki number koos oma andmetega kampaanialehel{' '}
          <a href="https://www.tere.eu/" target="_blank" rel="noreferrer">
            www.tere.eu
          </a>{' '}
          <span className="block">
            Kampaaniatooted on järgmised Tere tooted:{' '}
          </span>
          <ul>
            <li>Emma kohupiimakreem vaarikatega. Laktoosivaba 150 g</li>
            <li>Emma kohupiimakreem metsamarjadega. Laktoosivaba 150 g</li>
            <li>Emma vanilje kohupiimakreem 150g</li>
            <li>Emma kakao kohupiimakreem 150 g</li>
            <li>Emma rosina kohupiimakreem 150 g</li>
            <li>Emma banaani kohupiimakreem 150 g</li>
            <li>Emma mustika kohupiimakreem 150 g</li>
            <li>Emma maasika kohupiimakreem 150 g </li>
            <li>Emma kohupiimakreem rabarberikisselliga 150 g</li>
            <li>Emma kohupiimakreem vaarikakisselliga 150 g</li>
            <li>Emma kohupiimakreem mustikakisselliga 150 g</li>
            <li>Emma laktoosivaba kohupiimakreem jõhvikakisselliga 150 g</li>
            <li>Emma laktoosivaba kohupiimakreem maasikakisselliga 150 g</li>
            <li>
              Emma jäätisemaitseline jogurt šokolaadiriisikuulidega  145 g
            </li>
            <li>Emma jäätisemaitseline jogurt kommidega 145 g</li>
            <li>Emma jäätisemaitseline jogurt maisihelvestega 145 g</li>
            <li>Tere Emma banaanijogurt. Laktoosivaba 110g</li>
            <li>Tere Emma mustikajogurt. Laktoosivaba 110g</li>
            <li>Tere Emma maasikajogurt. Laktoosivaba 110g</li>
          </ul>
        </li>
        <li>
          Ostutšeki registreerimisega kampaanialehel kinnitab kampaanias osaleja
          oma isikuandmete õigsust, soovi osaleda kampaanias, kampaania
          reeglitest kinnipidamist ning annab nõusoleku oma isikuandmete
          töötlemisega kampaaniaga seotud eesmärkidel.
        </li>
        <li>Igat ostutšekki saab kampaanias registreerida vaid ühe korra.</li>
        <li>
          Peaauhinna loosis osalevad kõik kampaaniaperioodil korrektselt
          reigistreeritud ostutšekid. Vaheauhinna võitnud ostutšekid ei osale
          peaauhinna loosis.
        </li>
        <li>
          Peaauhinnad 10 perepiletit „Lotte ja kadunud jõuluvana“ etendusele
          loositakse peale kampaania perioodi (18.09-30.11.23) lõppu 01.12, kl
          10.00 ja avalikustatakse 1.12 kampaanialehel{' '}
          <a href="https://www.tere.eu/" target="_blank" rel="noreferrer">
            https://www.tere.eu/{' '}
          </a>
          ja Tere sotsiaalmeedia lehtedel Facebookis ja Instagramis.{' '}
        </li>
        <li>
          Vaheauhinnad loositakse 3 korral—29.09, 20.10 ja 10,11 Esimesel
          loosimisel osalevad 18.09 kl 00:00:00 kuni 28.09 kl 23:59:59
          registreeritud tšekid. Teisel vaheauhindade loosimisel osalevad 29.09
          kl 00:00:00 kuni 19.10 kl 23:59:59 registreeritud tšekid ja kolmandal
          loosimisel on loosimises 20.10 kl 00:00:00 kuni 09.11 kl 23:59:59
          registreeritud tšekid. Igal vaheauhinna loosimisel on 5 auhinna
          komplekti. Kokku loositakse 15 komplekti. Auhinna võitjad
          avalikustatakse peale loosimist hiljemalt järgmisel päeval
          kampaanialehel{' '}
          <a href="https://www.tere.eu/" target="_blank" rel="noreferrer">
            www.tere.eu{' '}
          </a>{' '}
          ja teavitatakse sotsiaalmeedias (Facebook, Instagram)
        </li>
        <li>
          Auhindade kättesaamise aluseks on ostutšeki digitaalne esitamine
          korraldajale. Ilma ostutšeki esitamiseta auhinda ei väljastata.
          Kampaania võitjatega võetakse korradaja poolt ühendust võidu
          kinnitamiseks ja auhindade kättetoimetamise tingimuste täpsustamiseks.
        </li>
        <li>
          Korraldaja ei vastuta osalejate auhindade loosimisest või auhindade
          kätteandmisest kõrvalejäämise eest, kui kampaanias osaleja on eiranud
          kampaania reegleid, esitanud korraldajale enda isiku kohta puudulikke
          andmeid või kui korraldajal ei õnnestu auhinna võitjaga kontakti saada
          7 tööpäeva jooksul või auhinda üle anda eelnevalt kokku lepitud kohas.
        </li>
        <li>
          Kampaania korraldaja võtab endale vastutuse kõigi võitudega seotud
          seadusjärgsete maksude tasumise eest.
        </li>
        <li>
          Kõik kampaaniaga seotud pretensioonid tuleb esitada kirjalikult
          korraldajale aadressil korraldab TERE AS, Lelle 22, Tallinn, Harjumaa,
          11318, EESTI, registrikood: 11411278, e-mail:{' '}
          <a href="mailto:tere@tere.eu">tere@tere.eu</a> märksõnaga „Osta Emma
          tooteid ja võida „Lotte ja Kadunud jõuluvana“ etenduse piletid!“.
        </li>
        <li>
          Korraldaja vastab pretensiooni esitajale kirjalikult 7 tööpäeva
          jooksul pretensiooni kättesaamise kuupäevast.
        </li>
        <li>
          Kampaania kohta saab lisainfot kampaania kodulehel{' '}
          <a href="https://www.tere.eu/" target="_blank" rel="noreferrer">
            https://www.tere.eu/
          </a>
          , või e-posti aadressil:{' '}
          <a href="mailto:tere@tere.eu">tere@tere.eu</a>{' '}
        </li>
        <li>
          Kampaanias ei või osaleda Tere AS-i ja kampaania korraldamisega seotud
          agentuuride töötajad ning nende pereliikmed.
        </li>
        <li>
          Kampaania korraldajal on õigus vääramatu jõu (force majeure)
          ilmnemisel kampaania ühepoolselt katkestada, teatades sellest
          viivitamatult massimeedia vahendusel.
        </li>
        <li>
          Kõik kampaaniast tulenevad vaidlused lahendatakse vastavalt Eesti
          Vabariigi seadustele.
        </li>
        <span className="block">Andmete töötlemise tingimused</span>
        <li>
          TERE AS töötleb kampaania käigus osaleja poolt esitatud isikuandmeid
          vastavalt Eesti Vabariigis kehtivatele seadustele kampaania
          läbiviimise eesmärgil.
        </li>
        <li>
          Kampaanias osaleja annab TERE AS-le nõusoleku töödelda esitatud
          andmeid järgnevatel eesmärkidel: kampaania tulemuste kohta kokkuvõtete
          tegemiseks; kampaaniaga seotud koostööpartneritele info edastamiseks;
          võitjate välja loosimiseks ja nende teavitamiseks; võitjate nimede
          avaldamiseks kampaania veebilehel ja sotsiaalmeedias.
        </li>
        <li>
          Kampaanias osalejatel on õigus saada lisainfot TERE AS poolt
          isikuandmete töötlemise kohta. Selleks tuleb saata e-kiri aadressile: 
          <a href="mailto:tere@tere.eu">tere@tere.eu</a>
        </li>
        <li>
          Isikuandmete vastutav töötleja on TERE AS, registrikood: 11411278,
          aadress: Lelle 22, 11318, Tallinn; telefon; +372 636 4113; email:
          <a href="mailto:tere@tere.eu">tere@tere.eu</a>
        </li>
        <li>
          Kogutud andmeid säilitatakse alates kampaania lõpukuupäevast ehk
          30.11-11.12.2023
        </li>
        <li>Võitjate täielik nimekiri on veebilehel üleval kuni 11.12.23</li>
      </ol>
    </div>
  );
};

export default Et;
